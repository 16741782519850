import React, { useState, useEffect } from "react";
import { useAppContext } from "./AppContext";
import { AppBar, Toolbar, Box, Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginService from "./services/loginService";

const Menubar = () => {
  const { loggedInUsername, setLoggedInUsername, isAdmin, setIsAdmin } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const navigate = useNavigate();

  const onUserInfoClicked = () => {
    navigate(`/users/${loggedInUsername}`);
  };

  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setShowUserMenu(true);
  };

  const onUserLogout = async () => {
    if (await loginService.userLogout(loggedInUsername)) {
      setLoggedInUsername("");
      setIsAdmin(false);
      navigate("/login")
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, justifyContent: "left" }}
            onClick={() => navigate("/vistaservers")}
          >
            Vista Servers
          </Button>
          <Button
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, justifyContent: "left" }}
            onClick={() => navigate("/certificates")}
          >
            Certificates
          </Button>
          {isAdmin && (
            <Button
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, justifyContent: "left", marginLeft: "2rem" }}
              onClick={() => navigate("/users")}
            >
              User Accounts
            </Button>
          )}
          <div>
            <Button
              id="user"
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              onClick={openUserMenu}              
            >
              User
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={showUserMenu}
              onClose={() => setShowUserMenu(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={onUserLogout}>Logout</MenuItem>
              <MenuItem onClick={onUserInfoClicked}>User Info</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Menubar;
