import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Typography,
} from "@mui/material";

const UserInfoDialog = (props) => {
  const { username, password, onClose } = props;
  return (
    <Dialog open={true}>
      <DialogTitle>User Info</DialogTitle>
      <DialogContent>
        <Typography>
          Password is shown only once, so copy it from here
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={username}
                ></TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Password</TableCell>
              <TableCell>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={password}
                ></TextField>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button id="close" onClick={onClose}>Close</Button>
      </DialogContent>
    </Dialog>
  );
};

export default UserInfoDialog;
