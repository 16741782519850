import axios from "axios";

const getServers = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + "/api/servers",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return { success: true, servers: response.data?.servers };
  } catch (error) {
    console.error("getServers response", error);
    return { success: false, loggedOut: error.response.status === 403 };
  }
};

const updateMachineGuid = async (serverUuid, machineGuid) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/machineguid`,
      {
        machineGuid,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 200 ? true : false;
  } catch (error) {
    console.error("updateMachineGuid response", error);
    return false;
  }
};

const deleteServer = async (serverUuid) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_API_SERVER + `/api/servers/${serverUuid}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 200 ? true : false;
  } catch (error) {
    console.error("deleteServer response", error);
    return false;
  }
};

const createServer = async (friendlyName) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/servers`,
      {
        friendlyName,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 201;
  } catch (error) {
    console.error("createServer response", error);
    return false;
  }
};

export default { getServers, deleteServer, createServer, updateMachineGuid };
