import React, { useEffect, useState } from 'react';
import certificateService from './services/certificateService';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import Menubar from '../Menubar';
import { convertUTCtoLocal } from '../utils/convertUTC';

const CertificatesPage = () => {
  const navigate = useNavigate();
  const [certificateData, setCertificateData] = useState();
  const [privateKeyData, setPrivateKeyData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchCertificate = async () => {
    const data = await certificateService.getCertificateData();
    if (data) {
      setCertificateData(data);
    } else {
      navigate('/login');
    }
  };

  const fetchPrivateKey = async () => {
    const data = await certificateService.getPrivateKeyData();
    if (data) {
      setPrivateKeyData(data);
    } else {
      navigate('/login');
    }
  };

  const fetchData = async () => {
    await fetchCertificate();
    await fetchPrivateKey();
  };

  const onUploadCertificateFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await certificateService.updateCertificate(file);
      await fetchCertificate();
    } else {
      console.error('onUploadCertificateClicked, no file defined');
    }
  };

  const onUploadPrivateKeyFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await certificateService.updatePrivateKey(file);
      await fetchPrivateKey();
    } else {
      console.error('onUploadCertificateClicked, no file defined');
    }
  };

  const onRefreshClicked = async () => {
    await fetchData();
  };

  return (
    <>
      <Menubar />
      <Button onClick={() => onRefreshClicked}>Refresh Data</Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certificateData && (
            <TableRow>
              <TableCell>{certificateData.name}</TableCell>
              <TableCell>
                {convertUTCtoLocal(certificateData.updatedAt)}
              </TableCell>
              <TableCell>{certificateData.uploaded_by_user}</TableCell>
              <TableCell>
                <input
                  accept='.crt'
                  style={{ display: 'none' }}
                  id='certificate-upload-button'
                  type='file'
                  onChange={(event) => onUploadCertificateFile(event)}
                />
                <label htmlFor='certificate-upload-button'>
                  <Button size='small' variant='contained' component='span'>
                    Upload
                  </Button>
                </label>
              </TableCell>
            </TableRow>
          )}
          {privateKeyData && (
            <TableRow>
              <TableCell>{privateKeyData.name}</TableCell>
              <TableCell>{privateKeyData.updatedAt}</TableCell>
              <TableCell>{privateKeyData.uploaded_by_user}</TableCell>
              <TableCell>
                <input
                  accept='.key'
                  style={{ display: 'none' }}
                  id='privatekey-upload-button'
                  type='file'
                  onChange={(event) => onUploadPrivateKeyFile(event)}
                />
                <label htmlFor='privatekey-upload-button'>
                  <Button size='small' variant='contained' component='span'>
                    Upload
                  </Button>
                </label>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default CertificatesPage;
