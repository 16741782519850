import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext";
import "../css/login.css";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { TextField, FormLabel, Typography, Button } from "@mui/material";

import ErrorDialog from "./ErrorDialog";

export const LoginProvider = () => {
  const { setLoggedInUsername, setIsAdmin, setIsInitialPassword } =
    useAppContext();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [helpText, setHelpText] = useState("");
  const [status, setStatus] = useState("idle");
  const createLoginBody = () => {
    return {
      username,
      password,
    };
  };

  const onLoginButtonClicked = async (event) => {
    event.preventDefault();
    try {
      setStatus("submitted");
      const response = await axios.post(
        process.env.REACT_APP_API_SERVER + "/api/users/login",
        createLoginBody(),
        { withCredentials: true }
      );
      response.status === 200 && setStatus("idle");
      setLoggedInUsername(response.data.username);
      setIsAdmin(response.data.role === "admin");
      setIsInitialPassword(response.data.isInitialPassword);
      if (response.data.isInitialPassword) {
        navigate(`/users/${response.data.username}`);
      } else {
        navigate("/vistaservers");
      }
    } catch (error) {
      if (error.response.data.message === "inactive") {
        setErrorText(
          "The account has not been used for a while, please ask password reset from the admin."
        );
        return;
      }

      if (error.message) {
        setErrorText("Login failed: " + error.message);
      } else {
        setErrorText("Login failed");
      }
    } finally {
      setStatus("idle");
    }
  };

  const onHelpDialogClosed = () => {
    setHelpText("");
  };

  const onErrorDialogClosed = () => {
    setErrorText(undefined);
  };

  return (
    <div className="Auth-form-container">
      {errorText && (
        <ErrorDialog
          errorMessage={errorText}
          onClose={onErrorDialogClosed}
        ></ErrorDialog>
      )}
      <form className="Auth-form">
        <div className="Auth-form-content">
          <div style={{ marginBottom: "20px" }}>
            <FormLabel>
              <Typography variant="body1" fontWeight="bold">
                Vivago License System
              </Typography>
            </FormLabel>
          </div>

          <div className="form-group mt-5">
            <div className="form-outline mb-2 fs-6">
              <TextField
                required
                id="username"
                size="large"
                inputProps={{ style: { fontSize: "16px" } }}
                label="Username"
                variant="standard"
                onChange={(e) => setUsername(e.target.value)}
                defaultValue={username}
              />
            </div>

            <div className="pwd">
              <TextField
                required
                id="password"
                type={showPassword ? "text" : "password"}
                size="large"
                inputProps={{ style: { fontSize: "16px" } }}
                label="Password"
                variant="standard"
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={password}
              />

              <div
                className="show"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </div>
            </div>

            <div className="d-grid mt-3">
              {status === "submitted" ? (
                <Button
                  disabled
                  id="submit"
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={(e) => onLoginButtonClicked(e)}
                >
                  Logging in ...
                </Button>
              ) : (
                <Button
                  id="submit"
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={(e) => onLoginButtonClicked(e)}
                >
                  Sign In
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
      {errorText && (
        <ErrorDialog
          errorMessage={errorText}
          onClose={() => onErrorDialogClosed()}
        />
      )}
    </div>
  );
};
