import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import serversService from './services/serversService';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import Menubar from '../Menubar';
import CreateNewServerDialog from './CreateNewServerDialog';
import { convertUTCtoLocal } from '../utils/convertUTC';

const ServersPage = () => {
  const { isAdmin } = useAppContext();
  const navigate = useNavigate();
  const [servers, setServers] = useState([]);
  const [showNewServerDialog, setShowNewServerDialog] = useState(false);

  useEffect(() => {
    if (!servers || servers.length === 0) {
      fetchServers();
    }
  }, []);

  const fetchServers = async () => {
    const result = await serversService.getServers();
    if (result.success) {
      setServers(result.servers);
    } else if (result.loggedOut) {
      navigate('/login');
    }
  };

  const onRefreshClicked = async () => {
    await fetchServers();
  };

  const onCreateNewServerClicked = () => {
    setShowNewServerDialog(true);
  };

  const onNewServerCreate = async (friendlyName) => {
    setShowNewServerDialog(false);
    if (!(await serversService.createServer(friendlyName))) {
      console.error('cannot create server');
    } else {
      await fetchServers();
    }
  };

  return (
    <>
      {showNewServerDialog && (
        <CreateNewServerDialog
          onCancel={() => setShowNewServerDialog(false)}
          onCreate={(friendlyName) => onNewServerCreate(friendlyName)}
        ></CreateNewServerDialog>
      )}
      <Menubar />
      <Button onClick={onRefreshClicked}>Refresh Data</Button>
      {isAdmin && (
        <Button
          style={{ marginLeft: '2rem' }}
          onClick={onCreateNewServerClicked}
        >
          Create New Server
        </Button>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Server Id</TableCell>
              <TableCell>Friendly Name</TableCell>
              <TableCell>Server Name</TableCell>
              <TableCell>Server DNS</TableCell>
              <TableCell>License Valid</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servers &&
              servers
                .sort((s1, s2) => (s1.username < s2.username ? -1 : 1))
                .map((server) => {
                  return (
                    <TableRow
                      onClick={() =>
                        navigate(`/serverdetails/${server.username}`)
                      }
                      key={server.username}
                    >
                      <TableCell>{server.username}</TableCell>
                      <TableCell>{server.friendly_name}</TableCell>
                      <TableCell>{server.srvname}</TableCell>
                      <TableCell>{server.server_dns}</TableCell>
                      <TableCell>{server.licenseValid}</TableCell>
                      <TableCell>{convertUTCtoLocal(server.createdAt)}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ServersPage;
