import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import licenseHistoryService from './licenseHistoryService';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Menubar from '../../Menubar';

const LicenseHistoryPage = () => {
  const { serverUuid, licenseId, siteId } = useParams();
  const navigate = useNavigate();
  const [licenseHistoryItems, setLicenseHistoryItems] = useState([]);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  };

  useEffect(() => {
    if (siteId) {
      getSiteLicenseHistory();
    } else {
      getServerLicenseHistory();
    }
  }, []);

  const getServerLicenseHistory = async () => {
    const response = await licenseHistoryService.getServerLicenseHistory(
      serverUuid,
      licenseId
    );
    if (response) {
      setLicenseHistoryItems(response);
    } else {
      navigate('/login');
    }
  };

  const getSiteLicenseHistory = async () => {
    const response = await licenseHistoryService.getSiteLicenseHistory(
      serverUuid,
      siteId,
      licenseId
    );
    if (response) {
      setLicenseHistoryItems(response);
    } else {
      navigate('login');
    }
  };

  const getUsername = (item) => {
    if (item.username?.length > 0) {
      return item.username;
    } else {
      return item.server_username;
    }
  };

  const convertUTCtoLocal = (utcDateTime) => {
    return new Date(utcDateTime).toLocaleString('en-Us', options);
  };

  return (
    <div>
      <Menubar />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Use</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenseHistoryItems &&
              licenseHistoryItems.map((item) => (
                <TableRow key={item.createdAt}>
                  <TableCell>{getUsername(item)}</TableCell>
                  <TableCell>{item.use}</TableCell>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{convertUTCtoLocal(item.createdAt)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LicenseHistoryPage;
