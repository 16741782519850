import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";

const ConfirmDialog = (props) => {
  const { header, content, onOk, onCancel } = props;
  return (
    <Dialog open={true}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <div>
        <Button id = "cancel" onClick={onCancel}>Cancel</Button>
        <Button id = "ok" onClick={onOk}>Ok</Button>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
