import React, {useState} from "react";
import { useAppContext } from "../AppContext";
import ServerSettings from "./ServerSettings";
import ServerLicenses from "./ServerLicenses";
import { LicenseDataProvider } from "./LicenseDataProvider";
import Menubar from "../Menubar";
import AdvancedSettings from "./AdvancedSettings/AdvancedSettings";

const ServerDetails = () => {
  const { isAdmin } = useAppContext();
  const [allowUpdate, setAllowUpdate] = useState(false);

  return (
    <div>
      <Menubar />
      <ServerSettings onAllowUpdate={(value) => setAllowUpdate(value) }></ServerSettings>
      <LicenseDataProvider allowUpdate={allowUpdate}>
        <ServerLicenses></ServerLicenses>
      </LicenseDataProvider>
      {isAdmin && <AdvancedSettings></AdvancedSettings>}
    </div>
  );
};

export default ServerDetails;
