import React, { useEffect, useState } from 'react';
import {
	TableBody,
	Dialog,
	DialogContent,
	DialogTitle,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Table,
	Button,
} from '@mui/material';
import licenseService from './services/licenseService';

const ConfirmLicenseUpdateDialog = (props) => {
	const { licenses, onCancel, onContinue } = props;
	const [licenseTypes, setLicenseTypes] = useState([]);

	useEffect(() => {
		const getLicenseTypes = licenseService
			.fetchLicenseTypes()
			.then((data) => {
				setLicenseTypes(data);
			})
			.catch((err) => {});
	}, []);

	licenseTypes.forEach((licType) => {
		for (const lic of licenses)
			if (lic.licenseId === licType.id) {
				lic.name = licType.name;
				break;
			}
	});

	return (
		<Dialog open={true}>
			<DialogTitle>Confirm License Update </DialogTitle>
			<DialogContent>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>License</TableCell>
								<TableCell>Old total count</TableCell>
								<TableCell>New total count</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{licenses &&
								licenses
									.sort((l1, l2) => l1.licenseId - l2.licenseId)
									.map((license) => (
										<TableRow key={license.licenseId}>
											<TableCell>{license.name}</TableCell>
											<TableCell>{license.origCount}</TableCell>
											<TableCell>{license.count}</TableCell>
										</TableRow>
									))}
						</TableBody>
					</Table>
				</TableContainer>
				<Button onClick={onCancel}>Cancel</Button>
				<Button onClick={onContinue}>Continue</Button>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmLicenseUpdateDialog;
