import axios from "axios";


export const LicenseTypes = {
	VIVA: 1,
	GO: 2,
	VITA: 3,
	LINK: 4,
	MAP: 5,
	NOTIO: 6,
	CLIENTS: 7,
	MOBILE: 8,
	QUICK: 9,
	SITES: 10,
	MOVE: 11,
  MOBILE_RELATIVES: 12
};


const fetchLicenses = async (serverUuid) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/servers/${serverUuid}/licenses`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data || [];
  } catch (error) {
    console.error("fetchLicenses", error);
    return undefined;
  }
};

const updateServerLicenses = async (serverUuid, licenses) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER + `/api/servers/${serverUuid}/licenses`,
      licenses,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error("updateSiteLicenses response", error.response?.data);
    return { success: false, error: error.response?.data?.error };
  }
};

const updateSiteLicenses = async (serverUuid, siteId, licenses) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/sites/${siteId}/licenses`,
      licenses,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return { success: response.status === 200 };
  } catch (error) {
    console.error("updateSiteLicenses response", error.response);
    console.error("updateSiteLicenses response", error.response.data);
    return { success: false, error: error.response.data.error };
  }
};

const getSiteLicenses = async (serverUuid, siteId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/sites/${siteId}/licenses`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("getSiteLicenses", error);
    return undefined;
  }
};

const fetchLicenseTypes = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/licenses`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("fetchLicenseTypes", error);
    return undefined;
  }
};

export default {
  fetchLicenses,
  fetchLicenseTypes,
  updateServerLicenses,
  getSiteLicenses,
  updateSiteLicenses,
};
