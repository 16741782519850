import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Button,
  TextField,
  Checkbox,
} from "@mui/material";
import serverSettingsService from "./services/serverSettingsService";
import ConfirmSettingsUpdateDialog from "./ConfirmSettingsUpdateDialog";

const ServerSettings = (props) => {
  const { serverUuid } = useParams();

  const [pollingIntervalOrig, setPollingIntervalOrig] = useState();
  const [pollingInterval, setPollingInterval] = useState(600);

  const [licenseValidDateOrig, setLicenseValidDateOrig] = useState();
  const [licenseValidDate, setLicenseValidDate] = useState("");

  const [allowUpdateOrig, setAllowUpdateOrig] = useState();
  const [allowUpdate, setAllowUpdate] = useState(false);

  const [enableLinkOrig, setEnableLinkOrig] = useState(false);
  const [enableLink, setEnableLink] = useState(false);

  const [enableQuickOrig, setEnableQuickOrig] = useState(false);
  const [enableQuick, setEnableQuick] = useState(false);

  const [friendlyNameOrig, setFriendlyNameOrig] = useState("");
  const [friendlyName, setFriendlyName] = useState("");

  const [machineGuid, setMachineGuid] = useState("");

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    refreshSettings();
  }, [serverUuid]);

  const refreshSettings = async () => {
    const settings = await serverSettingsService.fetchServerSettings(
      serverUuid
    );
    if (!settings) {
      navigate("/login");
    }
    setAllowUpdate(settings.allowUpdate);
    setAllowUpdateOrig(settings.allowUpdate);

    setPollingInterval(settings.pollingInterval);
    setPollingIntervalOrig(settings.pollingInterval);

    setLicenseValidDate(settings.licenseValidDate);
    setLicenseValidDateOrig(settings.licenseValidDate);

    setEnableLink(settings.enableLink);
    setEnableLinkOrig(settings.enableLink);

    setEnableQuick(settings.enableQuick);
    setEnableQuickOrig(settings.enableQuick);

    setFriendlyName(settings.friendlyName);
    setFriendlyNameOrig(settings.friendlyName);

    setMachineGuid(settings.machineguid);
  };

  useEffect(() => {
    const settingsChanged =
      allowUpdate !== allowUpdateOrig ||
      pollingInterval !== pollingIntervalOrig ||
      licenseValidDate !== licenseValidDateOrig ||
      enableLink !== enableLinkOrig ||
      enableQuick !== enableQuickOrig ||
      friendlyName !== friendlyNameOrig;

    setUpdateEnabled(settingsChanged);
  }, [
    allowUpdate,
    pollingInterval,
    licenseValidDate,
    enableLink,
    enableQuick,
    friendlyName,
  ]);

  const onUpdateClicked = () => {
    setConfirmationDialogOpen(true);
  };

  const onConfirmContinue = async () => {
    setConfirmationDialogOpen(false);

    if (
      await serverSettingsService.updateServerSettings(serverUuid, {
        licenseValidDate,
        pollingInterval,
        allowUpdate,
        enableLink,
        enableQuick,
        friendlyName,
      })
    ) {
      await refreshSettings();
    } else {
      navigate("/login");
    }
  };

  const onConfirmCancel = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <div>
      <ConfirmSettingsUpdateDialog
        enableLink={enableLink}
        enableLinkOrig={enableLinkOrig}
        enableQuick={enableQuick}
        enableQuickOrig={enableQuickOrig}
        allowUpdateOrig={allowUpdateOrig}
        allowUpdate={allowUpdate}
        pollingInterval={pollingInterval}
        pollingIntervalOrig={pollingIntervalOrig}
        licenseValidDate={licenseValidDate}
        licenseValidDateOrig={licenseValidDateOrig}
        friendlyNameOrig={friendlyNameOrig}
        friendlyName={friendlyName}
        open={confirmationDialogOpen}
        onCancel={onConfirmCancel}
        onContinue={onConfirmContinue}
      ></ConfirmSettingsUpdateDialog>
      <Button onClick={refreshSettings}>Refresh Data</Button>
      {!machineGuid && <Button href={`/api/servers/${serverUuid}/licensekey`}>Download License Key</Button>}
      <Typography>Server Settings</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1}>
              <TableCell>Friendly Name</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={friendlyName}
                  onChange={(e) => setFriendlyName(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>
            {allowUpdate !== undefined && (
              <TableRow key={2}>
                <TableCell>Allow updates from Vista</TableCell>
                <TableCell>
                  <Checkbox
                    checked={allowUpdate}
                    onChange={(e) => setAllowUpdate(e.target.checked)}
                  ></Checkbox>
                </TableCell>
              </TableRow>
            )}
            {enableLink !== undefined && (
              <TableRow key={3}>
                <TableCell>Link licenses</TableCell>
                <TableCell>
                  <Checkbox
                    checked={enableLink}
                    onChange={(e) => setEnableLink(e.target.checked)}
                  ></Checkbox>
                </TableCell>
              </TableRow>
            )}
            {enableQuick !== undefined && (
              <TableRow key={4}>
                <TableCell>Quick licenses</TableCell>
                <TableCell>
                  <Checkbox
                    checked={enableQuick}
                    onChange={(e) => setEnableQuick(e.target.checked)}
                  ></Checkbox>
                </TableCell>
              </TableRow>
            )}
            {pollingInterval !== undefined && (
              <TableRow key={5}>
                <TableCell>Vista polling interval (sec)</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={pollingInterval}
                    onChange={(e) => setPollingInterval(e.target.value)}
                  ></TextField>
                </TableCell>
              </TableRow>
            )}
            {licenseValidDate !== undefined && (
              <TableRow key={6}>
                <TableCell>License validity date</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={licenseValidDate}
                    onChange={(e) => setLicenseValidDate(e.target.value)}
                  ></TextField>
                </TableCell>
              </TableRow>
            )}
            <TableRow key={0}>
              <TableCell>GUID</TableCell>
              <TableCell>
                <Typography>{serverUuid}</Typography>
              </TableCell>
            </TableRow>

            {machineGuid !== undefined && (
              <TableRow key={7}>
                <TableCell>Machine Guid</TableCell>
                <TableCell>
                  <Typography>{machineGuid}</Typography>
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>
      <Button disabled={!updateEnabled} onClick={onUpdateClicked}>
        Update Settings
      </Button>
    </div>
  );
};

export default ServerSettings;
