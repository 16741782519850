import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { validate } from "uuid";
import ConfirmDialog from "../../ConfirmDialog";

const ChangeMachineGuidDialog = (props) => {
  const { onCancel, onUpdate, origGuid } = props;
  const [guid, setGuid] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    if (origGuid) {
      setGuid(origGuid);
    }
  }, [origGuid]);

  const onUpdateClicked = () => {
    setShowConfirmationDialog(true);
  };

  const onConfirmCancelClicked = () => {
    setShowConfirmationDialog(false);
  };

  const onConfirmOkClicked = () => {    
    onUpdate(guid);
    setShowConfirmationDialog(false);
  };

  return (
    <>
      {showConfirmationDialog && (
        <ConfirmDialog
          header={"Warning!"}
          onOk={onConfirmOkClicked}
          onCancel={onConfirmCancelClicked}
          content={"Are you sure you want to update the machinge guid?"}
        ></ConfirmDialog>
      )}
      <Dialog open={true}>
        <DialogTitle>Update Machine GUID</DialogTitle>
        <DialogContent>
          <form>
            <Typography>Machinge GUID</Typography>
            <TextField
              placeholder="UUID"
              value={guid}
              error={guid !== '' && !validate(guid)}
              onChange={(event) => {
                setGuid(event.target.value);
              }}
            ></TextField>
          </form>
          <Button onClick={onCancel}>Cancel</Button>
          <Button disabled={guid !== '' && !validate(guid)} onClick={onUpdateClicked}>Update</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeMachineGuidDialog;
