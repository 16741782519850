import React from "react";
import "../css/error.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const history = useNavigate();

  const onBack = () => {
    history(-1);
  };

  return (
    <div className="error">
      <h1 className="error--text">Page Not found!</h1>
      <Button className="error-button" onClick={() => onBack()}>
        Previous page
      </Button>
    </div>
  );
};
