import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Button,
  TextField,
} from "@mui/material";
import ErrorDialog from "../ErrorDialog";
import ServiceProviderDetails from "./ServiceProviderDetails";
import ConfirmLicenseUpdateDialog from "./ConfirmLicenseUpdateDialog";
import licenseService from "./services/licenseService";
import { useLicenseDataContext } from "./LicenseDataProvider";

const ServerLicenses = () => {
  const { licenseTypes } = useLicenseDataContext();
  const { serverUuid } = useParams();
  const [serverLicenses, setServerLicenses] = useState();
  const [updateServerEnabled, setUpdateServerEnabled] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [showLicenses, setShowLicenses] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLicenses = async () => {
      const data = await licenseService.fetchLicenses(serverUuid);
      if (data) {
        setServerLicenses(data.licenseData);
        setServiceProviders(data.srvproviders);
      } else {
        navigate("/login");
      }
    };

    if (serverUuid) {
      fetchLicenses();
    }
  }, [serverUuid]);

  useEffect(() => {
    if (licenseTypes && serverLicenses) {
      for (const licenseType of licenseTypes) {
        let license = serverLicenses.find(
          (l) => l.licenseId === licenseType.id
        );
        if (!license) {
          license = { licenseId: licenseType.id, use: 0, count: 0 };
          setServerLicenses([...serverLicenses, license]);
          return;
        }
      }
    }
  }, [licenseTypes, serverLicenses]);

  const updateServerLicenseCount = (id, count) => {
    const license = serverLicenses.find((license) => license.licenseId === id);
    if (license.origCount === undefined) {
      setUpdateServerEnabled(true);
      license.origCount = license.count || 0;
    }
    license.count = Number(count);
    setServerLicenses([...serverLicenses]);
  };

  const refreshLicenses = async () => {
    const data = await licenseService.fetchLicenses(serverUuid);
    if (data) {
      setServiceProviders(data.srvproviders);
      setServerLicenses(data.licenseData);
      setUpdateServerEnabled(false);
    } else {
      navigate("/login");
    }
  };

  const onUpdateServerClicked = () => {
    setConfirmationDialogOpen(true);
  };

  const onConfirmContinue = async () => {
    setConfirmationDialogOpen(false);
    const result = await licenseService.updateServerLicenses(
      serverUuid,
      serverLicenses
    );
    await refreshLicenses();
    if (!result.success) {
      setErrorMessage(result.error);
    }
  };

  const onErrorDialogClosed = () => {
    setErrorMessage(undefined);
  };

  const onConfirmCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const getLicense = (licenseId) => {
    let license = serverLicenses.find((l) => l.licenseId === licenseId);
    return license || { licenseId, use: 0, count: 0 };
  };

  return (
    <div>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onClose={() => onErrorDialogClosed()}
        ></ErrorDialog>
      )}

      {serverLicenses && confirmationDialogOpen && (
        <ConfirmLicenseUpdateDialog
          licenses={serverLicenses.filter((l) => l?.origCount !== undefined)}
          onCancel={onConfirmCancel}
          onContinue={onConfirmContinue}
        ></ConfirmLicenseUpdateDialog>
      )}

      <Typography>Server licenses</Typography>
      <div>
        <Button onClick={() => setShowLicenses(!showLicenses)}>
          {showLicenses ? "Hide Licenses" : "Show Licenses"}
        </Button>
      </div>
      {showLicenses && (
        <>
          <Button onClick={refreshLicenses}>Refresh Data</Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }}>
              <TableHead>
                <TableRow>
                  <TableCell>License Name</TableCell>
                  <TableCell>License Description</TableCell>
                  <TableCell>Used</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serverLicenses &&
                  licenseTypes &&
                  licenseTypes.map((licenseType) => (
                    <TableRow key={licenseType.id}>
                      <TableCell>{licenseType.name}</TableCell>
                      <TableCell
                        style={{ minWidth: "20px", maxWidth: "200px" }}
                      >
                        {licenseType.description}
                      </TableCell>
                      <TableCell>{getLicense(licenseType.id).use}</TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "40px", maxWidth: "80px" }}
                          size="small"
                          value={getLicense(licenseType.id).count}
                          onChange={(e) =>
                            updateServerLicenseCount(
                              licenseType.id,
                              e.target.value
                            )
                          }
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          onClick={() =>
                            navigate(
                              `/history/server/${serverUuid}/${licenseType.id}`
                            )
                          }
                        >
                          Show History
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            disabled={!updateServerEnabled}
            onClick={onUpdateServerClicked}
          >
            Update licenses
          </Button>
        </>
      )}

      {serviceProviders &&
        serviceProviders
          .filter((e) => e.providerId > 1)
          .map((provider) => (
            <ServiceProviderDetails
              key={provider.providerId}
              serviceProvider={provider}
            ></ServiceProviderDetails>
          ))}
    </div>
  );
};

export default ServerLicenses;
