import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/App.css";
import { LoginProvider } from "./components/Login";
import ServersPage from "./components/server/ServersPage";
import ServerDetails from "./components/server/ServerDetails";
import UsersPage from "./components/users/UsersPage";
import UserDetailsPage from "./components/users/UserDetailsPage";
import CertificatesPage from "./components/certificates/CertificatesPage";
import LicenseHistoryPage from "./components/server/licenseHistory/LicenseHistoryPage";
import { LicenseClientAppProvider } from "./components/AppContext";
import { PageNotFound } from "./components/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <LicenseClientAppProvider>
        <Routes>
          <Route path="/" element={<LoginProvider />} />
          <Route path="/login" element={<LoginProvider />} />
          <Route path="/vistaservers" element={<ServersPage />} />
          <Route
            path="/serverDetails/:serverUuid"
            element={<ServerDetails />}
          />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/certificates" element={<CertificatesPage />} />
          <Route path="/users/:username" element={<UserDetailsPage />} />
          <Route
            path="/history/server/:serverUuid/:licenseId"
            element={<LicenseHistoryPage />}
          />
          <Route
            path="/history/server/:serverUuid/site/:siteId/:licenseId"
            element={<LicenseHistoryPage />}
          />
          <Route
            path="*"
            element={<PageNotFound/>}
          />
        </Routes>
      </LicenseClientAppProvider>
    </BrowserRouter>
  );
}

export default App;
