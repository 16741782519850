import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import SiteLicenses from "./SiteLicenses";

const ServiceProviderDetails = (props) => {
  const { serviceProvider } = props;
  const [showSites, setShowSites] = useState(false);

  return (
    <>
      <div>
        <Typography style={{ fontSize: "1.5rem", marginTop: "1rem" }}>
          Service Provider: {serviceProvider.name} 
        </Typography>
        <Button onClick={() => setShowSites(!showSites)}>
          {showSites ? "Hide Sites" : "Show Sites"}
        </Button>
      </div>
      {showSites &&
        serviceProvider.sites &&
        serviceProvider.sites.map((site) => (
          <SiteLicenses site={site} key={site.id} />
        ))}
    </>
  );
};

export default ServiceProviderDetails;
