import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Menubar from '../Menubar';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import ConfirmDialog from '../ConfirmDialog';
import UserCreatedDialog from './UserInfoDialog';
import userService from './services/userService';
import { useAppContext } from '../AppContext';

const UsersDetailsPage = () => {
  const { username } = useParams();
  const { isAdmin, isInitialPassword } = useAppContext();
  const navigate = useNavigate();
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [newPassword, setNewPasswrd] = useState('');
  const [role, setRole] = useState('');
  const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = useState(false);
  const [showConfirmResetDialog, setShowConfirmResetDialog] = useState(false);
  const [showUserInfoDialog, setShowUserInfoDialog] = useState(false);

  useEffect(() => {
    if (username) {
      fetchUserData();
    }
  }, [username]);

  const fetchUserData = async () => {
    const user = await userService.fetchUser(username);
    if (user) {
      setLastName(user.lastName);
      setFirstName(user.firstName);
      setRole(user.role);
    } else {
      navigate('/login');
    }
  };

  const onRemoveUserClicked = () => {
    setShowConfirmRemoveDialog(true);
  };

  const onResetUserClicked = () => {
    setShowConfirmResetDialog(true);
  };

  const removeUser = async () => {
    if (await userService.removeUser(username)) {
      setShowConfirmRemoveDialog(false);
      navigate('/users');
    } else {
      navigate('/login');
    }
  };

  const resetUser = async () => {
    const userData = await userService.resetUser(username);
    if (userData) {
      setNewPasswrd(userData.password);
      setShowConfirmResetDialog(false);
      setShowUserInfoDialog(true);
    } else {
      navigate('/login');
    }
  };

  const onUseCreatedDialogClose = () => {
    setNewPasswrd('');
    setShowUserInfoDialog(false);
    navigate('/users');
  };

  return (
    <>
      <Menubar />
      {showUserInfoDialog && (
        <UserCreatedDialog
          onClose={onUseCreatedDialogClose}
          username={username}
          password={newPassword}
        ></UserCreatedDialog>
      )}
      {showConfirmRemoveDialog && (
        <ConfirmDialog
          header={`Remove User`}
          content={`Are you sure you want to remove user ${username}`}
          onOk={() => removeUser()}
          onCancel={() => setShowConfirmRemoveDialog(false)}
        ></ConfirmDialog>
      )}
      {showConfirmResetDialog && (
        <ConfirmDialog
          header={`Reset User`}
          content={`Are you sure you want to reset user account: ${username}`}
          onOk={() => resetUser()}
          onCancel={() => setShowConfirmResetDialog(false)}
        ></ConfirmDialog>
      )}
      {isInitialPassword && (
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='h5' color={'Highlight'}>
            You must reset the password before using the app
          </Typography>
        </Box>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>
              {username != undefined && (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={username}
                ></TextField>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>First name</TableCell>
            <TableCell>
              {firstName != undefined && (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={firstName}
                ></TextField>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last name</TableCell>
            <TableCell>
              {lastName != undefined && (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={lastName}
                ></TextField>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>
              {role != undefined && (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={role}
                ></TextField>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {isAdmin && (
        <Button id='remove' onClick={() => onRemoveUserClicked()}>
          Remove User
        </Button>
      )}
      <Button id='reset' onClick={() => onResetUserClicked()}>
        Reset Password
      </Button>
    </>
  );
};

export default UsersDetailsPage;
