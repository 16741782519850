import React from "react";
import {
  TableBody,
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Button,
} from "@mui/material";

const ConfirmSettingsUpdateDialog = (props) => {
  const {
    enableLinkOrig,
    enableLink,
    enableQuick,
    enableQuickOrig,
    allowUpdateOrig,
    allowUpdate,
    pollingIntervalOrig,
    pollingInterval,
    licenseValidDateOrig,
    licenseValidDate,
    friendlyNameOrig,
    friendlyName,
    open,
    onCancel,
    onContinue,
  } = props;
  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Server Settings Update</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Setting</TableCell>
                <TableCell>Old value</TableCell>
                <TableCell>New value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {friendlyName !== friendlyNameOrig && (
                <TableRow>
                  <TableCell>Friendly Name</TableCell>
                  <TableCell>{friendlyNameOrig}</TableCell>
                  <TableCell>{friendlyName}</TableCell>
                </TableRow>
              )}
              {allowUpdateOrig !== allowUpdate && (
                <TableRow>
                  <TableCell>Allow updating from Vista service</TableCell>
                  <TableCell>{allowUpdateOrig ? "true" : "false"}</TableCell>
                  <TableCell>{allowUpdate ? "true" : "false"}</TableCell>
                </TableRow>
              )}
              {enableLinkOrig !== enableLink && (
                <TableRow>
                  <TableCell>Link license</TableCell>
                  <TableCell>{enableLinkOrig ? "true" : "false"}</TableCell>
                  <TableCell>{enableLink ? "true" : "false"}</TableCell>
                </TableRow>
              )}
              {enableQuickOrig !== enableQuick && (
                <TableRow>
                  <TableCell>Quick license</TableCell>
                  <TableCell>{enableQuick ? "true" : "false"}</TableCell>
                  <TableCell>{enableQuickOrig ? "true" : "false"}</TableCell>
                </TableRow>
              )}
              {pollingIntervalOrig !== pollingInterval && (
                <TableRow>
                  <TableCell>Vista service polling interval</TableCell>
                  <TableCell>{pollingIntervalOrig}</TableCell>
                  <TableCell>{pollingInterval}</TableCell>
                </TableRow>
              )}
              {licenseValidDateOrig !== licenseValidDate && (
                <TableRow>
                  <TableCell>License validity date</TableCell>
                  <TableCell>{licenseValidDateOrig}</TableCell>
                  <TableCell>{licenseValidDate}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onContinue}>Continue</Button>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmSettingsUpdateDialog;
