import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  TextField,
} from "@mui/material";

const CreateNewServerDialog = (props) => {
  const { onCancel, onCreate } = props;
  const [friendlyName, setFriendlyName] = useState("");

  return (
    <>
      <Dialog open={true}>
        <DialogTitle>New Server</DialogTitle>
        <DialogContent>
          <form>
            <Typography>Servers Friendly Name</Typography>
            <TextField placeholder="friendly name"
              value={friendlyName}
              onChange={(event) => {
                setFriendlyName(event.target.value);
              }}></TextField>
          </form>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onCreate(friendlyName)}>Create</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateNewServerDialog;
