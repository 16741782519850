import axios from "axios";

const fetchAllUsers = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + "/api/users",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("fetchAllUsers", error);
    return undefined;
  }
};

const fetchUser = async (username) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/users/${username}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 200 ? response.data : undefined;
  } catch (error) {
    console.error("fetchUser error: ", error);
    return undefined;
  }
};

const addUser = async (username, firstname, lastname, role) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/users",
      {
        username,
        firstname,
        lastname,
        role,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 201 ? response.data : undefined;
  } catch (error) {
    console.error("addUsers error: ", error);
    return undefined;
  }
};

const removeUser = async (username) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_API_SERVER + `/api/users/${username}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error("removeUser error: ", error);
    return false;
  }
};

const resetUser = async (username) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/users/${username}/resetpassword`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 200 ? response.data : undefined;
  } catch (error) {
    console.error("resetUser error: ", error);
    return undefined;
  }
};

export default {
  fetchAllUsers,
  fetchUser,
  addUser,
  removeUser,
  resetUser,
};
