const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short',
};

const convertUTCtoLocal = (utcDateTime) => {
  return new Date(utcDateTime).toLocaleString('en-Us', options);
};

export {convertUTCtoLocal}