import axios from "axios";

const updateCertificate = async (file) => {
  if (!file) {
    console.error("file not defined");
    return;
  }
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/ssl/certificate`,
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error("updateCertificate", error);
    return undefined;
  }
};

const updatePrivateKey = async (file) => {
  if (!file) {
    console.error("file not defined");
    return;
  }
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/ssl/privatekey`,
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error("updatePrivateKey", error);
    return undefined;
  }
};

const getCertificateData = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/ssl/certificate`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response.status === 200 ? response.data : undefined;
  } catch (error) {
    console.error("getCertificateData", error);
    return undefined;
  }
};

const getPrivateKeyData = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/ssl/privatekey`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.status === 200 ? response.data : undefined;
  } catch (error) {
    console.error("getCertificateData", error);
    return undefined;
  }
};

export default {
  getCertificateData,
  getPrivateKeyData,
  updateCertificate,
  updatePrivateKey,
};
