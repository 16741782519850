import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

const ErrorDialog = (props) => {
  const { errorMessage, onClose } = props;
  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>{errorMessage}</Typography>
      </DialogContent>
      <Button id = "close" onClick={onClose}>Ok</Button>
    </Dialog>
  );
};

export default ErrorDialog;
