import axios from 'axios';

const getServerLicenseHistory = async (serverUuid, licenseId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/licenses/${licenseId}/audit`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error('getServerLicenseHistory', error);
    return undefined;
  }
};

const getSiteLicenseHistory = async (serverUuid, siteId, licenseId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/sites/${siteId}/licenses/${licenseId}/audit`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error('getServerLicenseHistory', error);
    return undefined;
  }
};

export default {
  getServerLicenseHistory,
  getSiteLicenseHistory,
};
