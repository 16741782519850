import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLicenseDataContext } from './LicenseDataProvider';
import { LicenseTypes } from './services/licenseService';

import {
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Paper,
	TableBody,
	TextField,
	Button,
} from '@mui/material';
import ConfirmLicenseUpdateDialog from './ConfirmLicenseUpdateDialog';
import licenseService from './services/licenseService';
import ErrorDialog from '../ErrorDialog';

const SiteLicenses = (props) => {
	const { site } = props;
	const navigate = useNavigate();
	const [licenseData, setLicenseData] = useState();
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [updateButtonEnabled, setUpdateButtonEnabled] = useState(false);
	const { licenseTypes, allowUpdate } = useLicenseDataContext();
	const { serverUuid } = useParams();
	const [errorMessage, setErrorMessage] = useState(undefined);

	useEffect(() => {
		if (site) {
			fetchSiteLicenses();
		}
	}, [site]);

	const fetchSiteLicenses = async () => {
		const siteLicenses = await licenseService.getSiteLicenses(
			serverUuid,
			site.id
		);
		if (!siteLicenses) {
			navigate('/login');
		}
		setLicenseData(siteLicenses);
		setUpdateButtonEnabled(false);
	};

	useEffect(() => {
		if (licenseTypes && licenseData) {
			for (const licenseType of licenseTypes) {
				let license = licenseData.find((l) => l.licenseId === licenseType.id);
				if (!license) {
					license = { licenseId: licenseType.id, use: 0, count: 0 };
					setLicenseData([...licenseData, license]);
					return;
				}
			}
		}
	}, [licenseData, licenseTypes]);

	const getLicense = (licenseId) => {
		if (!licenseData) {
			return { use: 0, count: 0 };
		}

		const license = licenseData.find((l) => l.licenseId === licenseId);
		return license || { use: 0, count: 0 };
	};

	const updateLicenseCount = (id, count) => {
		if (!licenseData) {
			return;
		}
		const license = licenseData.find((license) => license.licenseId === id);
		if (license.origCount === undefined) {
			setUpdateButtonEnabled(true);
			license.origCount = license.count;
		}
		license.count = Number(count);
		setLicenseData([...licenseData]);
	};

	const onUpdateButtonClicked = async () => {
		setConfirmationDialogOpen(true);
	};

	const onConfirmCancel = async () => {
		setConfirmationDialogOpen(false);
	};

	const onConfirmContinue = async () => {
		const result = await licenseService.updateSiteLicenses(
			serverUuid,
			site.id,
			licenseData
		);
		if (!result.success) {
			setErrorMessage(result.error);
		}
		await fetchSiteLicenses();
		setConfirmationDialogOpen(false);
	};

	const onErrorDialogClosed = () => {
		setErrorMessage(undefined);
	};

	return (
		<>
			<Typography style={{ fontSize: '1rem', marginTop: '1rem' }}>
				Site: {site.name}
			</Typography>

			{errorMessage && (
				<ErrorDialog
					errorMessage={errorMessage}
					onClose={() => onErrorDialogClosed()}
				></ErrorDialog>
			)}

			{licenseData && confirmationDialogOpen && (
				<ConfirmLicenseUpdateDialog
					licenses={licenseData.filter((l) => l?.origCount !== undefined)}
					onCancel={onConfirmCancel}
					onContinue={onConfirmContinue}
				></ConfirmLicenseUpdateDialog>
			)}
			<Button onClick={fetchSiteLicenses}>Refresh Data</Button>

			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>License Name</TableCell>
							<TableCell>Used</TableCell>
							<TableCell>Total</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{licenseTypes &&
							licenseTypes
								.sort((l1, l2) => l1.id - l2.id)
								.filter(f => f.id != LicenseTypes.SITES).map((licenseType) => {
									return (
										<TableRow key={licenseType.id}>
											<TableCell>{licenseType.name}</TableCell>
											<TableCell>{getLicense(licenseType.id).use}</TableCell>
											<TableCell>
												<TextField
													disabled={allowUpdate}
													style={{ minWidth: '40px', maxWidth: '80px' }}
													size='small'
													value={getLicense(licenseType.id).count}
													onChange={(e) =>
														updateLicenseCount(licenseType.id, e.target.value)
													}
												></TextField>
											</TableCell>
											<TableCell>
												<Button
													size='small'
													onClick={() =>
														navigate(
															`/history/server/${serverUuid}/site/${site.id}/${licenseType.id}`
														)
													}
												>
													Show history
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				disabled={!updateButtonEnabled}
				onClick={() => onUpdateButtonClicked()}
			>
				Update licenses
			</Button>
		</>
	);
};

export default SiteLicenses;
