import React, { useState, createContext, useContext, useEffect } from "react";

const LicenseClientAppContext = createContext();

const LicenseClientAppProvider = ({ children }) => {
  const [loggedInUsername, _setLoggedInUsername] = useState("");
  const [isAdmin, _setIsAdmin] = useState(false);
  const [isInitialPassword, _setIsInitialPassword] = useState(false);

  useEffect(() => {
    _setIsAdmin(window.localStorage.getItem("isAdmin") === "true");
    _setLoggedInUsername(window.localStorage.getItem("username"));
    _setIsInitialPassword(window.localStorage.getItem("initialPassword") === "true");
  }, []);

  const setIsAdmin = (newIsAdminValue) => {
    window.localStorage.setItem("isAdmin", newIsAdminValue);
    _setIsAdmin(newIsAdminValue);
  };

  const setLoggedInUsername = (newLoggedInUsername) => {
    window.localStorage.setItem("username", newLoggedInUsername);
    _setLoggedInUsername(newLoggedInUsername);
  };

  const setIsInitialPassword = (newIsInitialPassword) => {
    window.localStorage.setItem("initialPassword", newIsInitialPassword);
    _setIsInitialPassword(newIsInitialPassword);
  };

  return (
    <LicenseClientAppContext.Provider
      value={{
        isAdmin,
        setIsAdmin,
        loggedInUsername,
        setLoggedInUsername,
        setIsInitialPassword,
        isInitialPassword
      }}
    >
      {children}
    </LicenseClientAppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(LicenseClientAppContext);
};

export { LicenseClientAppProvider, useAppContext };
