import axios from "axios";

const fetchAllowUpdate = async (serverUuid) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/allowUpdate`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data.allowUpdate;
  } catch (error) {
    console.error("fetchAllowUpdate", error);
    return undefined;
  }
};

const updateAllowUpdate = async (serverUuid, allowUpdate) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/allowUpdate`,
      {
        allowUpdate,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.status === 200;
  } catch (error) {
    console.error("updateAllowUpdate", error);
    return false;
  }
};

const fetchPollingInterval = async (serverUuid) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/pollingInterval`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data.pollingInterval;
  } catch (error) {
    console.error("fetchPollingInterval", error);
    return undefined;
  }
};

const updatePollingInterval = async (serverUuid, pollingInterval) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/pollingInterval`,
      {
        pollingInterval,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.status === 200;
  } catch (error) {
    console.error("updatePollingInterval", error);
    return false;
  }
};

const fetchLicenseValidDate = async (serverUuid) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/licensevaliddate`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data.licenseValidDate;
  } catch (error) {
    console.error("fetchLicenseValidDate", error);
    return undefined;
  }
};

const updateLicenseValidDate = async (serverUuid, licenseValidDate) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER +
        `/api/servers/${serverUuid}/licensevaliddate`,
      {
        licenseValidDate,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.status === 200;
  } catch (error) {
    console.error("updateLicenseValidDate", error);
    return false;
  }
};

const updateServerSettings = async (serverUuid, settings) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_SERVER + `/api/servers/${serverUuid}`,
      settings,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.status === 200;
  } catch (error) {
    console.error("updateLicenseValidDate", error);
    return false;
  }
};

const fetchServerSettings = async (serverUuid) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/servers/${serverUuid}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("fetchServerSettings", error);
    return false;
  }
};

export default {
  fetchAllowUpdate,
  updateAllowUpdate,
  fetchPollingInterval,
  updatePollingInterval,
  fetchLicenseValidDate,
  updateLicenseValidDate,
  updateServerSettings,
  fetchServerSettings,
};
