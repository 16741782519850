import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import userService from "./services/userService";
import UserCreatedDialog from "./UserInfoDialog";
import { Navigate } from "react-router-dom";

const AddUserDialog = (props) => {
  const { open, onClose } = props;

  const [username, setUsername] = useState("<username>");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("basic");
  const [showUserCreatedDialog, setShowUserCreatedDialog] = useState(false);

  const onAddUserClicked = async () => {
    const userAdded = await userService.addUser(
      username,
      firstName,
      lastName,
      role
    );

    setShowUserCreatedDialog(true);
    if (userAdded) {
      setPassword(userAdded.password);
    } else {
      Navigate("/login");
    }
  };

  const onUseCreatedDialogClose = () => {
    setPassword("");
    setShowUserCreatedDialog(false);
    onClose();
  };

  return (
    <>
      {showUserCreatedDialog && (
        <UserCreatedDialog
          onClose={onUseCreatedDialogClose}
          username={username}
          password={password}
        ></UserCreatedDialog>
      )}
      <Dialog open={open}>
        <DialogTitle>User Info</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>
                  <TextField
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>First name</TableCell>
                <TableCell>
                  <TextField
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Last name</TableCell>
                <TableCell>
                  <TextField
                  id = "lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Role</TableCell>
                <TableCell>
                  <Select
                    id = "roleSelector"
                    value={role}
                    label="Role"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem id = "basic" value={"basic"}>Basic</MenuItem>
                    <MenuItem id = "admin" value={"admin"}>Admin</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button id = "cancel" onClick={onClose}>Cancell</Button>
          <Button id = "add" onClick={onAddUserClicked}>Add User</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUserDialog;
