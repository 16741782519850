import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { convertUTCtoLocal } from '../utils/convertUTC';

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import Menubar from '../Menubar';
import userService from './services/userService';
import AddUserDialog from './AddUserDialog';

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const isInactiveUser = (user) => {
    let resp = false;
    const userLastLogin = moment(user.lastLogin);
    const sixMonthsAgo = moment().subtract(6, 'months');
    return userLastLogin.isBefore(sixMonthsAgo);
  };

  const fetchUsers = async () => {
    const users = await userService.fetchAllUsers();

    if (!users) {
      navigate('/login');
    } else {
      setUsers(users);
    }
  };

  const onAddNewUserClicked = () => {
    setShowAddUserDialog(true);
  };

  const onAddNewUserDialogClosed = async () => {
    setShowAddUserDialog(false);
    await fetchUsers();
  };

  return (
    <>
      <Menubar />
      <AddUserDialog
        open={showAddUserDialog}
        onClose={onAddNewUserDialogClosed}
      ></AddUserDialog>

      <Button onClick={() => onAddNewUserClicked()}>Add User</Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Latest login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((u) => {
              return (
                <TableRow
                  style={
                    isInactiveUser(u) ? { backgroundColor: 'lightgray' } : {}
                  }
                  onClick={() => navigate(`/users/${u.username}`)}
                  key={u.username}
                >
                  <TableCell id='username'>{u.username}</TableCell>
                  <TableCell>{u.firstName}</TableCell>
                  <TableCell>{u.lastName}</TableCell>
                  <TableCell>{u.role}</TableCell>
                  <TableCell>{convertUTCtoLocal(u.lastLogin)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UsersPage;
