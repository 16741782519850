import React, { useState, createContext, useContext, useEffect } from "react";
import licenseService from "./services/licenseService";

const LicenseDataContext = createContext();

const LicenseDataProvider = (props) => {
  const {children, allowUpdate} = props;
  const [licenseTypes, setLicenseTypes] = useState([]);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      const types = await licenseService.fetchLicenseTypes();
      setLicenseTypes(types);
    };

    fetchLicenseTypes();
  }, []);

  return (
    <LicenseDataContext.Provider value={{ licenseTypes, allowUpdate }}>
      {children}
    </LicenseDataContext.Provider>
  );
};

const useLicenseDataContext = () => {
  return useContext(LicenseDataContext);
};

export { LicenseDataProvider, useLicenseDataContext };
