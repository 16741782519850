import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "@mui/material";
import ConfirmDialog from "../../ConfirmDialog";
import serversService from "../services/serversService";
import ChangeMachineGuidDialog from "./ChangeMachineGuidDialog";
import ErrorDialog from "../../ErrorDialog";

const AdvancedSettings = (props) => {
  const navigate = useNavigate();
  const { serverUuid } = useParams();
  const [showSettings, setShowSettings] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showMachineGuidUpdateDialog, setShowMachineGuidUpdateDialog] =
    useState(false);
  const [errorText, setErrorText] = useState(undefined);

  const onRemoveServerClicked = () => {
    setShowConfirmDeleteDialog(true);
  };

  const onUpdateMachineGuidClicked = () => {
    setShowMachineGuidUpdateDialog(true);
  };

  const onConfirmDeleteOk = async () => {
    setShowConfirmDeleteDialog(false);
    if (await serversService.deleteServer(serverUuid)) {
      navigate("/vistaservers");
    } else {
      navigate("/login");
    }
  };

  const onUpdateGuid = async (newGuid) => {
    setShowMachineGuidUpdateDialog(false);
    if (await serversService.updateMachineGuid(serverUuid, newGuid)) {
      // for reloading the current page
      navigate(0);
    } else {
      setErrorText("Failed to update the machine guid");
    }
  };

  const onConfirmationCancel = async () => {
    setShowConfirmDeleteDialog(false);
    setShowMachineGuidUpdateDialog(false);
  };

  const onErrorDialogClosed = () => {
    setErrorText(undefined);
  };

  return (
    <div>
      {errorText && (
        <ErrorDialog
          errorMessage={errorText}
          onClose={onErrorDialogClosed}
        ></ErrorDialog>
      )}
      {showConfirmDeleteDialog && (
        <ConfirmDialog
          header={"Warning!"}
          onOk={onConfirmDeleteOk}
          onCancel={onConfirmationCancel}
          content={
            "Deleting a server cannot be undone, are you sure you want to continue"
          }
        ></ConfirmDialog>
      )}
      {showMachineGuidUpdateDialog && (
        <ChangeMachineGuidDialog
          originalGuid="test"
          onCancel={onConfirmationCancel}
          onUpdate={onUpdateGuid}
        ></ChangeMachineGuidDialog>
      )}
      <Button onClick={() => setShowSettings(!showSettings)}>
        Show Advanced Settings
      </Button>
      {showSettings && (
        <Container
          style={{
            marginLeft: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Button
            style={{ marginRight: "1rem" }}
            variant="outlined"
            color="warning"
            onClick={onRemoveServerClicked}
          >
            Remove Server
          </Button>

          <Button
            style={{ marginLeft: "1rem" }}
            variant="outlined"
            color="warning"
            onClick={onUpdateMachineGuidClicked}
          >
            Change Machine GUID
          </Button>
        </Container>
      )}
    </div>
  );
};

export default AdvancedSettings;
