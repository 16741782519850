import axios from "axios";

const userLogout = async (username) => {
  try {
    await axios.get(process.env.REACT_APP_API_SERVER + `/api/users/${username}/logout`, {withCredentials: true});
    return true;
  } catch (error) {
    console.error("logout failed", error);
    return false;
  }
};

export default {
    userLogout
}
